import { ElementType } from 'react';

import {
  AddressBook,
  Calendar,
  Cookie,
  CurrencyDollar,
  Horse,
  House,
  IconProps,
  Invoice,
  Person,
  UsersThree,
} from '@phosphor-icons/react';
import { MotionProps } from 'framer-motion';

import { Roles } from '@modules/auth/constants/Roles';

export interface IMenuSubItem {
  id: string;
  name: string; // Key to translate
  path: string;
  permission?: string;
}

export interface IMenuItem extends MotionProps {
  id: string;
  icon: ElementType<IconProps>;
  name: string; // Key to translate
  path: string;
  permission?: string;
  items?: IMenuSubItem[];
}

export const MenuItems: IMenuItem[] = [
  {
    id: 'home',
    icon: House,
    name: 'home',
    path: '/',
    permission: Roles.dashboard.GET_DASHBOARD,
  },
  {
    id: 'balances',
    icon: CurrencyDollar,
    name: 'balance',
    path: '/balances/dashboard',
    permission: Roles.balances.GET_BALANCE,
    items: [
      {
        id: 'balances-dashboard',
        name: 'balance_dashboard',
        path: '/balances/dashboard',
        permission: Roles.balances.GET_BALANCE,
      },
      {
        id: 'balances-invoices',
        name: 'balance_invoices',
        path: '/balances/invoices',
        // TODO: change permission
        permission: Roles.riders.GET_RIDER_PAYMENTS,
      },
      {
        id: 'balances-conciliation',
        name: 'balance_conciliation',
        path: '/balances/conciliation',
        permission: Roles.invoices.GET_CONCILIATION_DASHBOARD,
      },
      {
        id: 'balances-wallets',
        name: 'balance_wallets',
        path: '/balances/wallets',
        permission: Roles.balances.GET_BALANCE_WALLETS,
      },
      {
        id: 'balances-categories',
        name: 'balance_categories',
        path: '/balances/categories',
        permission: Roles.balances.GET_BALANCE_CATEGORIES,
      },
      {
        id: 'balances-reports',
        name: 'balance_reports',
        path: '/balances/reports',
        permission: Roles.balances.GET_BALANCE_REPORTS,
      },
    ],
  },
  {
    id: 'plans',
    icon: Invoice,
    name: 'plans',
    path: '/plans',
    // TODO: permission here
    // permission: Roles.teachers.GET_TEACHERS,
  },
  {
    id: 'stable',
    icon: Horse,
    name: 'stable',
    path: '/stable/horses',
    permission: Roles.horses.GET_HORSES,
    items: [
      {
        id: 'stable-horses',
        name: 'stable_horses',
        path: '/stable/horses',
        permission: Roles.horses.GET_HORSES,
      },
      {
        id: 'stable-horses-feeding',
        name: 'stable_horses_feeding',
        path: '/stable/horses/feeding',
        permission: Roles.horses.CREATE_HORSE_FEED,
      },
      {
        id: 'stable-horses-owners',
        name: 'stable_horses_owners',
        path: '/stable/horses/owners',
        permission: Roles.horses.GET_HORSE_OWNERS,
      },
    ],
  },
  {
    id: 'riders',
    icon: UsersThree,
    name: 'riders',
    path: '/riders',
    permission: Roles.riders.GET_RIDERS,
  },
  {
    id: 'teachers',
    icon: Person,
    name: 'teachers',
    path: '/teachers',
    permission: Roles.teachers.GET_TEACHERS,
  },
  {
    id: 'appointments',
    icon: Calendar,
    name: 'appointments',
    path: '/appointments',
    permission: Roles.appointments.GET_APPOINTMENTS,
    items: [
      {
        id: 'appointments-list',
        name: 'appointments_list',
        path: '/appointments/calendar',
        permission: Roles.appointments.GET_APPOINTMENTS,
      },
      {
        id: 'appointments-history-list',
        name: 'appointments_history_list',
        path: '/appointments/history',
        permission: Roles.appointments.GET_APPOINTMENTS_HISTORY,
      },
    ],
  },
  {
    id: 'warehouse',
    icon: Cookie,
    name: 'warehouse',
    path: '/warehouse/foods',
    permission: Roles.warehouse.GET_FOODS,
  },
  {
    id: 'contacts',
    icon: AddressBook,
    name: 'contacts',
    path: '/contacts',
    permission: Roles.contacts.GET_CONTACTS,
  },
];

import { ReactElement, Suspense } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAsync } from 'react-use';

import queryString from 'query-string';

import { Fallback } from '@shared/components/Fallback';

import { useProfile } from '@modules/profiles/hooks/useProfile';

import { useSubscriptions } from '@modules/subscriptions/hooks/useSubscriptions';

import { useAuth } from '../hooks/useAuth';

export const Auth = (): ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();

  const { signInByTokens, isAuthenticated } = useAuth();
  const { getCurrentProfile } = useProfile();
  const { getCurrentSubscription } = useSubscriptions();

  const { accessToken, equitesToken, refreshToken } = queryString.parse(location.search);

  useAsync(async () => {
    if (accessToken && equitesToken && refreshToken) {
      await signInByTokens({
        accessToken: String(accessToken),
        equitesToken: String(equitesToken),
        refreshToken: String(refreshToken),
      });
    }
  }, [accessToken, equitesToken, refreshToken]);

  useAsync(async () => {
    if (isAuthenticated) {
      await Promise.all([getCurrentProfile(), getCurrentSubscription()]);
      navigate('/', { replace: true });
      window.location.reload();
    }
  }, [isAuthenticated]);

  return <Suspense fallback={<Fallback.Navigation />} />;
};

import { tv } from 'tailwind-variants';

export const spinnerVariants = tv({
  base: 'animate-spin',
  variants: {
    color: {
      green: '',
      red: '',
      yellow: '',
    },
    variant: {
      primary: '',
      secondary: '',
      tertiary: '',
    },
  },
  compoundVariants: [
    {
      color: 'green',
      variant: 'primary',
      className: 'text-white',
    },
    {
      color: 'green',
      variant: 'secondary',
      className: 'text-green-500',
    },
    {
      color: 'green',
      variant: 'tertiary',
      className: 'text-green-500',
    },
    {
      color: 'red',
      variant: 'primary',
      className: 'text-white',
    },
    {
      color: 'red',
      variant: 'secondary',
      className: 'text-red-500',
    },
    {
      color: 'red',
      variant: 'tertiary',
      className: 'text-red-500',
    },
    {
      color: 'yellow',
      variant: 'primary',
      className: 'text-white',
    },
    {
      color: 'yellow',
      variant: 'secondary',
      className: 'text-yellow-600',
    },
    {
      color: 'yellow',
      variant: 'tertiary',
      className: 'text-yellow-600',
    },
  ],
});

export const buttonVariants = tv({
  base: 'w-full rounded-lg p-3 text-sm font-bold leading-none outline-none transition-colors duration-300 focus:ring-1 disabled:pointer-events-none disabled:cursor-not-allowed',
  variants: {
    isLoading: {
      true: '',
      false: '',
    },
    color: {
      green: '',
      red: '',
      yellow: '',
    },
    variant: {
      primary: 'border',
      secondary: 'border',
      tertiary: '',
    },
  },
  compoundVariants: [
    {
      color: 'green',
      variant: 'primary',
      className:
        'border-green-500 bg-green-500 text-white ring-green-500 hover:bg-green-500/70 disabled:border-opacity-40 disabled:bg-opacity-40 dark:border-green-500/80 dark:bg-green-500/80 dark:hover:bg-green-500/50',
    },
    {
      color: 'green',
      variant: 'secondary',
      className:
        'border-green-500 bg-transparent text-green-500 ring-green-500 hover:bg-green-500/20 disabled:border-opacity-40 disabled:bg-transparent disabled:text-opacity-40',
    },
    {
      color: 'green',
      variant: 'tertiary',
      className:
        'border-none bg-transparent text-green-500 ring-green-500 hover:bg-green-500/20 disabled:text-opacity-40 disabled:hover:bg-transparent',
    },
    {
      color: 'red',
      variant: 'primary',
      className:
        'border-red-500 bg-red-500 text-white ring-red-500 hover:bg-red-500/70 disabled:border-opacity-40 disabled:bg-opacity-40 dark:border-red-500/80 dark:bg-red-500/80 dark:hover:bg-red-500/50',
    },
    {
      color: 'red',
      variant: 'secondary',
      className:
        'border-red-500 bg-transparent text-red-500 ring-red-500 hover:bg-red-500/20 disabled:border-opacity-40 disabled:bg-transparent disabled:text-opacity-40',
    },
    {
      color: 'red',
      variant: 'tertiary',
      className:
        'border-none bg-transparent text-red-500 ring-red-500 hover:bg-red-500/20 disabled:text-opacity-40 disabled:hover:bg-transparent',
    },
    {
      color: 'yellow',
      variant: 'primary',
      className:
        'border-yellow-600 bg-yellow-600 text-white ring-yellow-600 hover:bg-yellow-600/70 disabled:border-opacity-40 disabled:bg-opacity-40 dark:border-yellow-600/80 dark:bg-yellow-600/80 dark:hover:bg-yellow-600/50',
    },
    {
      color: 'yellow',
      variant: 'secondary',
      className:
        'border-yellow-600 bg-transparent text-yellow-600 ring-yellow-600 hover:bg-yellow-600/20 disabled:border-opacity-40 disabled:bg-transparent disabled:text-opacity-40',
    },
    {
      color: 'yellow',
      variant: 'tertiary',
      className:
        'border-none bg-transparent text-yellow-600 ring-yellow-600 hover:bg-yellow-600/20 disabled:text-opacity-40 disabled:hover:bg-transparent',
    },
  ],
  defaultVariants: {
    color: 'green',
    variant: 'primary',
    isLoading: false,
  },
});
